import api from "../helper/request";

const createFeedback = (obj) => api.post(`/feedback-answer/create`, obj);

const getFeedback = () => api.get(`/feedback-answer/own`);

const getDep = () => api.get("/employees/department");

const getEmp = () => api.get("/employees/list");

export { createFeedback, getFeedback, getDep, getEmp };
