import React, { useContext, useEffect, useState } from "react";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { TitleContext } from "../context/TitleContext";
import { UserContext } from "../context/userContext";
import { getFeedback } from "../service/feedback.service";
import DesktopFeedback from "../components/Feedback/desktop/DesktopFeedback";
import MobileFeedback from "../components/Feedback/mobile/MobileFeedback";

const Feedback = () => {
  const { setTitle } = useContext(TitleContext);
  const { user } = useContext(UserContext);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);

  const [sendData, setSendData] = useState({});
  const [inboxData, setInboxData] = useState({});

  useEffect(() => {
    setTitle("Feedback");
  }, [setTitle]);

  useEffect(() => {
    if (user !== null) {
      setId(user.employeeId);
    }
  }, [user]);

  useEffect(() => {
    if (id !== null) {
      setLoading(true);
      // sendedFeedback(id)
      //   .then((data) => {
      //     setSendNumber(data.data?.length);
      //     const starts = data.data
      //       .filter((data) => data.type === "START")
      //       .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
      //     setStartSend(starts);

      //     const cons = data.data
      //       .filter((data) => data.type === "CONTINUE")
      //       .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
      //     setConSend(cons);

      //     const stops = data.data
      //       .filter((data) => data.type === "STOP")
      //       .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
      //     setStopSend(stops);
      //     setLoading(false);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     setLoading(false);
      //   });

      getFeedback()
        .then((data) => {
          const starts = data.data
            .filter((data) => data.type === "START")
            .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));

          const cons = data.data
            .filter((data) => data.type === "CONTINUE")
            .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));

          const stops = data.data
            .filter((data) => data.type === "STOP")
            .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));

          const all = data.data.sort(
            (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
          );

          setInboxData({
            all: all,
            number: data.data.length,
            start: starts,
            con: cons,
            stop: stops,
          });

          setSendData({
            all: [],
            number: 0,
            start: [],
            con: [],
            stop: [],
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [id]);

  if (loading || id === null) {
    return (
      <div className="flex items-center justify-center h-screen w-full">
        Loading...
      </div>
    );
  }

  if (
    Object.keys(sendData).length === 0 ||
    Object.keys(inboxData).length === 0
  ) {
    return (
      <div className="flex items-center justify-center h-screen w-full">
        Loading...
      </div>
    );
  }

  if (id === null) {
    return <div className="ms-4 mt-4 text-lg">There is no Employee</div>;
  }

  return (
    <div className="md:w-10/12 sm:w-full w-full m-auto pt-6">
      <div className="hidden sm:hidden md:block">
        <DesktopFeedback inboxData={inboxData} sendData={sendData} />
      </div>
      <div className="block sm:block md:hidden">
        <MobileFeedback inboxData={inboxData} sendData={sendData} />
      </div>
    </div>
  );
};

export default Feedback;
