import React, { useContext, useEffect, useRef, useState } from "react";
import { GiCheckMark } from "react-icons/gi";
import { IoIosArrowUp } from "react-icons/io";
import { IoMoonOutline } from "react-icons/io5";
import { LuSunDim } from "react-icons/lu";
import { IoCloseSharp } from "react-icons/io5";
import { checkAnswer, getOnboard } from "../service/onboard.service";
import { TitleContext } from "../context/TitleContext";
import { NightModeContext } from "../context/NightModeContext";
import "../style/animation.css";

const Modal = ({ taskData, onFileSubmit, setPopUp, nightMode, modalDone }) => {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [error, setError] = useState("");
  const [textArea, setTextArea] = useState("");

  useEffect(() => {
    if (modalDone && taskData) {
      setTextArea(taskData.text || "");
      setFile(taskData.img || null);
      setPreviewUrl(taskData.img || null);
      console.log(taskData.img);
    }
  }, [modalDone, taskData]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileSizeInMB = selectedFile.size / (1024 * 1024);
      if (fileSizeInMB > 25) {
        setError("File size should be less than 25MB.");
        setFile(null);
        setPreviewUrl(null);
        return;
      }

      setFile(selectedFile);
      setPreviewUrl(URL.createObjectURL(selectedFile));
      setError("");
    }
  };

  const handleSubmit = () => {
    if (!file && !textArea.trim()) {
      setError("Please provide either a description or an image.");
      return;
    }

    const data = {
      text: textArea,
      file: file,
    };

    console.log("sending data", data);
    onFileSubmit(data);
    setPopUp(false);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40">
      <div
        className={`${
          nightMode ? "bg-[#2E1B5B]" : "bg-white"
        } rounded-lg px-2 py-4 w-[335px]`}
      >
        <div className="flex items-start justify-between mb-4">
          <p
            className={`${
              nightMode ? "text-white" : "text-[#444] "
            } text-xs font-semibold`}
          >
            {taskData.taskName}
          </p>
          <button
            onClick={() => {
              setPopUp(false);
            }}
            className={`${nightMode ? "text-white" : "text-[#444] "} text-lg`}
          >
            <IoCloseSharp />
          </button>
        </div>
        <div>
          <p
            className={`${
              nightMode ? "text-white" : "text-[#1E293B]"
            } text-xs mb-2`}
          >
            Тайлбар бичнэ үү.
          </p>
          <textarea
            placeholder="Энд гүйцэтгэсэн task-аа үгээр тайлбарлаж бичнэ үү."
            value={textArea}
            disabled={modalDone}
            onChange={(e) => setTextArea(e.target.value)}
            className={`border rounded-xl h-[116px]  w-full text-[10px] px-2 py-1 ${
              nightMode
                ? "bg-[#fff] border-[#fff] bg-opacity-5 border-opacity-15 text-white"
                : "bg-[#F8F8F8] border-[#B0B0B0] text-[#1E293B]"
            }`}
          />
        </div>
        <div className="flex items-center justify-between gap-4">
          <div
            className={`${
              nightMode ? "bg-[#fff]" : "bg-[#000]"
            } w-[135px] bg-opacity-30 h-[1px]`}
          />
          <p
            className={`${
              nightMode ? "text-[#fff]" : "text-[#000]"
            } opacity-30 font-semibold text-xs`}
          >
            or
          </p>
          <div
            className={`${
              nightMode ? "bg-[#fff]" : "bg-[#000]"
            } w-[135px] bg-opacity-30 h-[1px]`}
          />
        </div>
        <div className="mt-2">
          <p
            className={`${
              nightMode ? "text-white" : "text-[#1F2937]"
            } font-semibold text-xs`}
          >
            Зураг оруулна уу.
          </p>
          <p
            className={`${
              nightMode ? "text-white" : "text-[#6B7280] "
            } text-[10px]`}
          >
            Хийж дуусгасан task-аа зургаар баталгаажуулах.
          </p>
          <div className="flex items-center justify-center w-full">
            <label
              htmlFor="file-upload"
              className="flex flex-col items-center justify-center w-full h-[130px] mt-4 border-2 border-[#BBBBBB] rounded-lg cursor-pointer hover:bg-gray-100 "
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                {previewUrl ? (
                  <img
                    className="w-full max-h-[110px] object-cover rounded"
                    src={previewUrl}
                    alt="Selected Preview"
                  />
                ) : (
                  <>
                    <img
                      className="w-[62px] h-[62px]"
                      src="./img/onboard/input.svg"
                      alt="input"
                    />
                    <p className="mt-2 text-xs text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">Click to upload</span> or
                      drag and drop
                    </p>
                    <p className="text-[10px] text-gray-500 dark:text-gray-400">
                      SVG, PNG, JPG or GIF
                    </p>
                  </>
                )}
              </div>
              <input
                disabled={modalDone}
                id="file-upload"
                type="file"
                className="hidden"
                onChange={handleFileChange}
                accept="image/*"
              />
            </label>
          </div>

          {error && <p className="mt-2 text-red-500 text-xs">{error}</p>}
          <button
            onClick={handleSubmit}
            className={`mt-4 w-full rounded-lg py-2 text-xs font-semibold ${
              nightMode ? "bg-[#fff] text-[#2E1B5B]" : "bg-[#2D2D2D] text-white"
            }`}
          >
            Илгээх
          </button>
        </div>
      </div>
    </div>
  );
};

const TaskItem = ({ task, isLastTask, nightMode, fetchOnboardData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const taskRef = useRef(null);
  const subTaskRef = useRef(null);
  const [taskHeight, setTaskHeight] = useState(0);
  const [popUp, setPopUp] = useState(false);
  const [instruction, setInstruction] = useState(false);
  const [modalData, setModalData] = useState("");
  const [data, setData] = useState(null);
  const [subtaskHeight, setSubtaskHeight] = useState(0);
  const [modalDone, setModalDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subtaskStates, setSubtaskStates] = useState(
    task.questions.map((subtask) => subtask.isFinish)
  );

  const handleSubmit = (file) => {
    setData(file);
  };

  useEffect(() => {
    console.log("submitted data", data);
  }, [data]);

  useEffect(() => {
    const updateTaskHeight = () => {
      let totalHeight = 10;

      if (taskRef.current) {
        totalHeight += taskRef.current.clientHeight;
      }

      if (subTaskRef.current && isOpen) {
        totalHeight += subTaskRef.current.scrollHeight;
        setSubtaskHeight(subTaskRef.current.scrollHeight);
      }

      if (subTaskRef.current && !isOpen && subtaskHeight !== 0) {
        setTimeout(() => {
          totalHeight -= subtaskHeight;
          setTaskHeight(totalHeight);
        }, 200);
      }
      setTaskHeight(totalHeight);
    };

    updateTaskHeight();

    window.addEventListener("resize", updateTaskHeight);

    return () => {
      window.removeEventListener("resize", updateTaskHeight);
    };
  }, [isOpen, subtaskHeight]);

  const getTaskStatus = () => {
    const subtasksDone = task.questions.map((subtask) => subtask.isFinish);
    const allDone = subtasksDone.every((done) => done === true);
    const anyError = subtasksDone.includes("error");
    const allfalse = subtasksDone.every((done) => done === false);
    const anyFalse = subtasksDone.includes(false);

    if (anyError) return "error";
    if (allDone) return "finished";
    if (allfalse) return "unfinished";
    if (anyFalse && subtasksDone.length > 0) return "ongoing";
  };

  const unfinishedQuestionsCount = task.questions.filter(
    (question) => !question.isFinish
  ).length;

  const taskStatus = getTaskStatus();

  const handleClicked = async (subtask, index) => {
    setLoading(true);
    setSubtaskStates((prev) =>
      prev.map((state, i) => (i === index ? !state : state))
    );
    try {
      const checking = {
        questionId: subtask.id,
        isFinished: !subtask.isFinish,
      };

      await checkAnswer(checking);

      fetchOnboardData();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div
        style={{ cursor: loading ? "wait" : "default" }}
        ref={taskRef}
        className="relative w-full flex justify-between mb-4 "
      >
        <div
          className={`relative z-20 mt-6 ${
            isLastTask ? " overflow-hidden" : ""
          }`}
        >
          {taskStatus === "finished" ? (
            <div
              className={`w-[16px] h-[16px] rounded-xl ${
                nightMode ? "bg-[#00CC99]" : "bg-[#fff]"
              } border border-[#00CC99] flex items-center justify-center relative z-20`}
            >
              <GiCheckMark
                className={`w-[10px] ${
                  nightMode ? "text-[#fff]" : "text-[#00CC99]"
                }`}
              />
            </div>
          ) : taskStatus === "error" ? (
            <div
              className={`w-[16px] h-[16px] rounded-xl ${
                nightMode ? "bg-[#FF5F6E]" : "bg-[#FFEDEF]"
              } border border-[#FF5F6E] flex items-center justify-center relative z-20`}
            >
              <img
                className={`h-[10px]`}
                src={`./img/onboard/${
                  nightMode ? "errorNight.svg" : "error.svg"
                }`}
                alt="icon"
              />
            </div>
          ) : taskStatus === "ongoing" ? (
            <div
              className={`w-[16px] h-[16px] rounded-xl ${
                nightMode ? "bg-[#FFDE2F]" : "bg-[#FFF9D8]"
              } border border-[#FFDE2F] flex items-center justify-center relative z-20`}
            >
              <img
                className="h-[10px]"
                src={`./img/onboard/${
                  nightMode ? "ongoingNight.svg" : "ongoing.svg"
                }`}
                alt="icon"
              />
            </div>
          ) : (
            <div className="w-[16px] h-[16px] rounded-xl bg-[#CECFD3] border border-[#CECFD3] flex items-center justify-center relative z-20">
              <GiCheckMark className="w-[7px] text-[#CECFD3]" />
            </div>
          )}
          {!isLastTask && (
            <div
              style={{ height: `${taskHeight}px` }}
              className={`absolute w-[6px] ${
                taskStatus === "finished"
                  ? "bg-[#00CC99]"
                  : taskStatus === "error"
                  ? "bg-[#FF5F6E]"
                  : "bg-[#EEEFF1]"
              } mt-4 left-[5px] -top-[5px] z-10`}
            />
          )}
        </div>
        <div
          className={`w-[90%] px-3 py-2 rounded-lg ${
            nightMode
              ? "border border-[#fff] border-opacity-15 bg-[#fff] bg-opacity-5"
              : "shadow-custom"
          }`}
        >
          <div
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center justify-between relative cursor-pointer"
          >
            <div className="flex items-center space-x-2">
              {/* <img src={task.img} alt="img" /> */}
              <div>
                <span
                  className={`font-semibold text-sm ${
                    nightMode ? "text-[#fff]" : "text-[#1E293B]"
                  }`}
                >
                  {task.name}{" "}
                  {taskStatus === "finished" && (
                    <span className="text-xs text-[#27AE60]">
                      {task.score}pts
                    </span>
                  )}
                </span>
                {taskStatus === "finished" ? (
                  <p
                    className={`text-xs opacity-80 ${
                      nightMode ? "text-[#fff]" : "text-[#1E293B] opacity-80"
                    }`}
                  >
                    Дуусгасан
                  </p>
                ) : taskStatus === "ongoing" ? (
                  <p
                    className={`text-xs opacity-80 ${
                      nightMode ? "text-[#fff]" : "text-[#1E293B] opacity-80"
                    }`}
                  >
                    {unfinishedQuestionsCount} таск дутуу
                  </p>
                ) : (
                  <p
                    className={`text-xs opacity-80 ${
                      nightMode ? "text-[#fff]" : "text-[#1E293B] opacity-80"
                    }`}
                  >
                    {task.score}pts
                  </p>
                )}
              </div>
            </div>
            <button
              className={`transition ${!isOpen ? "rotate-180" : "rotate-0"}`}
            >
              <IoIosArrowUp
                className={`${nightMode ? "text-[#fff]" : "text-[#1E293B]"}`}
              />
            </button>
          </div>
          <div
            ref={subTaskRef}
            className={`overflow-hidden transition-all duration-300 ease-in-out ${
              isOpen ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
            }`}
          >
            <div className="mt-4">
              {task.questions.map((subtask, index) => {
                // Check if subtask.text is a URL
                const isUrl = (text) => {
                  const urlPattern = new RegExp(
                    "^(https?:\\/\\/)?" + // protocol
                      "((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|" + // domain name
                      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                      "(\\:\\d+)?(\\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?" + // port and path
                      "(\\?[;&a-zA-Z0-9%_\\+.~#?&//=]*)?" + // query string
                      "(\\#[-a-zA-Z0-9_]*)?$",
                    "i"
                  );
                  return urlPattern.test(text);
                };

                return (
                  <div
                    onClick={() => {
                      if (!loading && !isUrl(subtask.text)) {
                        handleClicked(subtask, index);
                      }
                    }}
                    key={index}
                    className="flex items-center cursor-pointer gap-2 w-full py-3"
                  >
                    <div
                      onClick={() => {
                        if (isUrl(subtask.text)) {
                          handleClicked(subtask, index);
                        }
                      }}
                    >
                      {subtask.isFinish === true ? (
                        <div
                          className={`flex items-center justify-center w-[14px] h-[14px] border rounded-[2px] border-[#00CC99] ${
                            nightMode ? "bg-[#00CC99]" : "bg-[#fff]"
                          }`}
                        >
                          <GiCheckMark
                            className={`w-[10px] ${
                              nightMode ? "text-[#fff]" : "text-[#00CC99]"
                            }`}
                          />
                        </div>
                      ) : subtask.isFinish === false &&
                        taskStatus === "ongoing" ? (
                        <div
                          className={`flex items-center justify-center w-[14px] h-[14px] border rounded-[2px] border-[#E5E6E8] ${
                            nightMode ? " bg-[#E5E6E8]" : "bg-[#fff]"
                          }`}
                        ></div>
                      ) : subtask.isFinish === "error" ? (
                        <div className="flex items-center justify-center w-[14px] h-[14px] border rounded-[2px] border-[#FF5F6E] bg-[#FFEDEF]">
                          <img
                            className="h-[11px]"
                            src={`./img/onboard/${
                              nightMode ? "errorNight.svg" : "error.svg"
                            }`}
                            alt="icon"
                          />
                        </div>
                      ) : (
                        <div className="flex items-center justify-center w-[14px] h-[14px] border rounded-[2px] border-[#BCBCBC] bg-[#EBEBEB]"></div>
                      )}
                    </div>
                    <div className="w-[90%] ">
                      {isUrl(subtask.text) ? (
                        <a
                          href={subtask.text}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`text-xs underline ${
                            nightMode ? "text-[#fff]" : "text-[#2C4360]"
                          }`}
                        >
                          {subtask.text}
                        </a>
                      ) : (
                        <p
                          className={`text-xs underline ${
                            nightMode ? "text-[#fff]" : "text-[#2C4360]"
                          }`}
                        >
                          {subtask.text}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {instruction && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40">
            <div
              className={`${
                nightMode ? "bg-[#2E1B5B]" : "bg-white "
              } rounded-lg px-2 py-4 w-[335px]`}
            >
              <div className="flex items-center justify-between mb-4">
                <p
                  className={`${
                    nightMode ? "text-white" : "text-[#1F2937]"
                  } text-sm font-semibold`}
                >
                  Дараах даалгаврыг гүйцэтгэхдээ:
                </p>
                <button
                  onClick={() => {
                    setInstruction(false);
                  }}
                  className={`${
                    nightMode ? "text-white" : "text-[#1F2937]"
                  } text-lg`}
                >
                  <IoCloseSharp />
                </button>
              </div>
              <div>
                <p
                  className={`text-xs ${
                    nightMode ? "text-white" : "text-[#1F2937]"
                  }`}
                >
                  {task.instruction}
                </p>
                <button
                  onClick={() => {
                    setPopUp(true);
                    setInstruction(false);
                  }}
                  className={`mt-4 w-full rounded-lg py-2 text-xs font-semibold ${
                    nightMode
                      ? "bg-[#fff] text-[#2E1B5B]"
                      : "bg-[#2D2D2D] text-white"
                  }`}
                >
                  Ойлголоо
                </button>
              </div>
            </div>
          </div>
        )}
        {popUp && (
          <Modal
            taskData={modalData}
            onFileSubmit={handleSubmit}
            setPopUp={setPopUp}
            nightMode={nightMode}
            modalDone={modalDone}
          />
        )}
      </div>
    </>
  );
};

const OnBoarding = () => {
  const { setTitle } = useContext(TitleContext);
  const [loading, setLoading] = useState(true);
  const { nightMode, setNightMode } = useContext(NightModeContext);
  const [onboard, setOnboard] = useState(null);
  const [coinPopUp, setCoinPopUp] = useState(false);
  const [score, setScore] = useState(0);
  const [height, setHeight] = useState(window.innerHeight - 63);
  const [isEnded, setIsEnded] = useState(false);

  const fetchOnboardData = async () => {
    try {
      const data = await getOnboard();
      setOnboard(data.data);
      const totalScore = data.data.onboardTopics.reduce((sum, item) => {
        return sum + (item.score !== null ? item.score : 0);
      }, 0);
      setLoading(false);

      setScore(totalScore);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOnboardData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setTitle("Onboarding");
  }, [setTitle]);

  const getFullyCompletedTasksCount = () => {
    return onboard?.onboardTopics.filter((task) =>
      task.questions.every((subtask) => subtask.isFinish === true)
    ).length;
  };

  useEffect(() => {
    const totalTasks = onboard?.onboardTopics.length;
    const completedTasks = getFullyCompletedTasksCount();

    if (completedTasks === totalTasks && totalTasks > 0) {
      setIsEnded(true);
    }
  }, [onboard?.onboardTopics]);

  if (loading) {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        Loading...
      </div>
    );
  }

  if (onboard === null || onboard.length === 0) {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        Onboard doesnt aply
      </div>
    );
  }

  const getFullyCompletedTasksScore = () => {
    return onboard.onboardTopics
      .filter((task) =>
        task.questions.every((subtask) => subtask.isFinish === true)
      )
      .reduce((sum, task) => {
        return sum + (task.score || 0);
      }, 0);
  };

  const calculateCompletionPercentage = () => {
    const totalTasks = onboard.onboardTopics.length;
    const completedTasks = getFullyCompletedTasksCount();
    return ((completedTasks / totalTasks) * 100).toFixed(0);
  };

  const handleToggle = () => {
    setNightMode(!nightMode);
  };

  const completionPercentage = calculateCompletionPercentage();

  const completedTasksScore = getFullyCompletedTasksScore();

  const isMobile = window.innerWidth <= 768;
  return (
    <div
      style={{
        minHeight: isMobile ? `${height - 64}px` : `${height}px`,
      }}
      className={` relative ${nightMode ? "bg-[#2E1B5B]" : "bg-none"}`}
    >
      <div className="w-10/12 pt-11 m-auto ">
        <div className="flex items-start justify-between mb-6 relative">
          <div className="flex items-center gap-4">
            <div
              className={`rounded-xl ${
                nightMode
                  ? "border border-[#fff] border-opacity-15 bg-[#fff] bg-opacity-5"
                  : "shadow-xl"
              } w-[100px] h-[80px] px-2 py-1 flex items-center justify-start z-20`}
            >
              <div>
                <p
                  className={`text-[9px] mb-2 text-center ${
                    nightMode ? "text-white" : "text-[#324d72]"
                  }`}
                >
                  Гүйцэтгэлийн явц
                </p>
                <div>
                  <div className="px-4 w-full bg-[#3AC267] relative rounded-xl bg-opacity-20 h-[8px]">
                    <div
                      className={`absolute top-0 left-0 h-[8px] bg-[#3AC267] rounded-xl`}
                      style={{ width: `${completionPercentage}%` }}
                    ></div>
                  </div>
                  <div className="flex items-center justify-between mt-2">
                    <div>
                      <p
                        className={`${
                          nightMode ? "text-white" : "text-[#324D72]"
                        } font-semibold text-sm`}
                      >
                        {getFullyCompletedTasksCount()}/
                        <span
                          className={`text-xs ${
                            nightMode ? "text-white" : "opacity-70"
                          }`}
                        >
                          {onboard.onboardTopics.length}
                        </span>
                      </p>
                    </div>
                    <p
                      className={` ${
                        nightMode ? "text-white" : "text-[#324d72]"
                      } text-sm font-semibold"`}
                    >
                      {calculateCompletionPercentage()}%
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              onClick={() => setCoinPopUp(true)}
              className={`rounded-xl cursor-pointer ${
                nightMode
                  ? "border border-[#fff] border-opacity-15 bg-[#fff] bg-opacity-5"
                  : "shadow-xl"
              } w-[100px] h-[80px] px-2 py-1 flex items-center justify-start z-20`}
            >
              <div>
                <p
                  className={`text-xs ${
                    nightMode ? "text-white" : "text-[#324d72]"
                  }`}
                >
                  Таны оноо
                </p>
                <div>
                  <img
                    className="my-1 w-[20px] h-[20px]"
                    src="./img/onboard/coin.svg"
                    alt="coin"
                  />

                  <p
                    className={`${
                      nightMode ? "text-white" : "text-[#324d72]"
                    } font-semibold text-sm`}
                  >
                    {completedTasksScore}
                    <span
                      className={`text-xs ${!nightMode ? "opacity-70" : ""}`}
                    >
                      {" "}
                      points
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center z-20">
            <button
              onClick={handleToggle}
              className={`${
                !nightMode
                  ? "bg-[#F4F6FB]  border-[#324D72]"
                  : "bg-[#FFFFFF] bg-opacity-15 border-[#FFFFFF] border-opacity-35"
              } relative border-2 inline-flex h-6 w-12 items-center rounded-full transition-colors duration-300 ease-in-out focus:outline-none`}
            >
              <span
                className={`${
                  !nightMode
                    ? "translate-x-[26px] bg-[#324D72]"
                    : "translate-x-1 bg-[#EEEEEE]"
                } inline-block h-4 w-4 transform rounded-full transition-transform duration-300 ease-in-out relative`}
              >
                <img
                  className="absolute right-0.5 top-1/2 transform -translate-y-1/2"
                  src="./img/onboard/toggleLight.svg"
                  alt="toggle"
                />
              </span>
              <div
                className={`${
                  !nightMode
                    ? "absolute left-1 text-[#090a0b]"
                    : "absolute right-1 text-gray-600"
                } text-sm`}
              >
                {nightMode ? (
                  <LuSunDim className="text-[#EEEEEE] " />
                ) : (
                  <IoMoonOutline className="text-[#324D72] font-bold" />
                )}
              </div>
            </button>
          </div>
          {nightMode && (
            <img
              className="top-4 -right-6 absolute z-10"
              src="./img/onboard/cloud.svg"
              alt="cloud"
            />
          )}
        </div>
        <div className="w-auto mb-10">
          {onboard.onboardTopics.map((task, index) => (
            <TaskItem
              key={index}
              task={task}
              isLastTask={index === onboard.onboardTopics.length - 1}
              nightMode={nightMode}
              fetchOnboardData={fetchOnboardData}
            />
          ))}
        </div>
      </div>
      {nightMode && (
        <img
          className="fixed bottom-0 w-full h-auto z-30"
          src="/img/onboard/grass.svg"
          alt="grass"
        />
      )}
      {coinPopUp && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40">
          <div
            className={`${
              nightMode ? "bg-[#2E1B5B]" : "bg-white"
            } rounded-lg px-4 py-3 w-[360px]`}
          >
            <div className="flex items-center gap-4 justify-between mb-4">
              <p
                className={`text-sm ${
                  nightMode ? "text-[#fff]" : "text-[#1E293B]"
                }`}
              >
                Оноогоо бүрэн цуглуулж 1000 хүрмэгц та хүний нөөцийн ажилтнаас
                бэлгээ аваарай
              </p>
              <img src="/img/onboard/smile.svg" alt="smile" />
            </div>
            <div className="w-full rounded-md relative h-[8px] bg-[#3AC267] bg-opacity-20">
              <div
                className="h-[8px] rounded-md absolute left-0 top-0 bg-[#3AC267]"
                style={{ width: `${completionPercentage}%` }}
              ></div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center gap-1">
                <img
                  className="w-[16px] h-[16px]"
                  src="/img/onboard/coin.svg"
                  alt="coing"
                />
                <p
                  className={`text-sm ${
                    nightMode ? "text-[#fff]" : "text-[#1E293B]"
                  }`}
                >
                  {completedTasksScore}
                </p>
              </div>
              <div className="flex items-center gap-1">
                <img src="/img/onboard/present.svg" alt="gift" />
                <p
                  className={`text-sm ${
                    nightMode ? "text-[#fff]" : "text-[#1E293B]"
                  }`}
                >
                  {score}
                </p>
              </div>
            </div>
            <button
              onClick={() => {
                setCoinPopUp(false);
              }}
              className={`w-full py-1 text-center rounded-lg text-xs mt-4 ${
                nightMode
                  ? "text-[#2E1B5B] bg-[#fff]"
                  : "text-[#fff] bg-[#1E293B]"
              }`}
            >
              Ойлголоо
            </button>
          </div>
        </div>
      )}

      {isEnded && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40">
          <div className="relative flex justify-center">
            {/* Left-to-right animation */}
            <div className="absolute top-2 left-4 animate-slide-in-left">
              <p className="text-[32px] text-[#FFF]">Баяр хүргэе!</p>
              <div className="flex items-center gap-2">
                <img
                  className="w-[24px]"
                  src="/img/onboard/coin.svg"
                  alt="coin"
                />
                <p className="text-[#fff]">{completedTasksScore}</p>
              </div>
            </div>

            <img src="/img/onboard/ending/background.svg" alt="background" />
            <img
              className="absolute top-[105px]  animate-slide-in-bottom"
              src="/img/onboard/ending/gift.svg"
              alt="gift"
            />

            <div
              className={`${
                nightMode ? "bg-[#2E1B5B]" : "bg-white"
              } absolute top-[190px] w-full rounded-b-lg px-4 py-3 opacity-0 animate-slide-in-bottom-delayed`}
            >
              <div className="flex items-center gap-2">
                <p
                  className={`text-xs ${
                    nightMode ? "text-[#fff]" : "text[#1E293B]"
                  }`}
                >
                  Та хүний нөөцийн ажилтнаас бэлгээ аваарай
                </p>
                <img src="/img/onboard/ending/smile.svg" alt="smile" />
              </div>
              <div className="flex justify-end mt-2">
                <button
                  onClick={() => {
                    setIsEnded(false);
                  }}
                  className={`text-xs py-2 px-3 rounded-md ${
                    nightMode
                      ? "text-[#1E293B] bg-[#fff]"
                      : "bg-[#1E293B] text-[#fff]"
                  }`}
                >
                  Ойлголоо
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnBoarding;
