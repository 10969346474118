// src/pages/NotFound.js
import React from "react";

const NotFound = () => {
  return (
    <div className="h-screen w-full m-auto flex items-center justify-center">
      <div>
        <img src="./img/404.avif" alt="404" />
      </div>
    </div>
  );
};

export default NotFound;
