import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./partials/Login";
import LoginById from "./partials/LoginById";
import Home from "./pages/Home";
import PrivateRoute from "./components/PrivateRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./partials/Layout";
import MyProfile from "./pages/MyProfile";
import OnBoarding from "./pages/OnBoarding";
import NotFound from "./partials/NotFound";

import { UserProvider } from "./context/userContext";
import { TitleProvider } from "./context/TitleContext";
import { SidebarProvider } from "./context/SidebarContext";
import { NightModeProvider } from "./context/NightModeContext";
import Feedback from "./pages/Feedback";
import FeedbackEmp from "./pages/feedback/FeedbackEmp";
import FeedbackSearch from "./pages/feedback/FeedbackSearch";

const App = () => {
  return (
    <UserProvider>
      <SidebarProvider>
        <TitleProvider>
          <NightModeProvider>
            <Router>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/company/:word/:id" element={<LoginById />} />
                <Route
                  element={
                    <PrivateRoute>
                      <Layout />
                    </PrivateRoute>
                  }
                >
                  <Route path="/" element={<Home />} />

                  {/* profile category */}
                  <Route path="/profile" element={<MyProfile />} />

                  {/* onboard category */}
                  <Route path="/onboard" element={<OnBoarding />} />

                  {/* feedback category */}
                  <Route path="/feed-forward" element={<Feedback />} />
                  <Route
                    path="/feed-forward/search"
                    element={<FeedbackSearch />}
                  />
                  <Route path="/feed-forward/:id" element={<FeedbackEmp />} />
                </Route>
                <Route path="*" element={<NotFound />} />{" "}
              </Routes>
            </Router>
            <ToastContainer />
          </NightModeProvider>
        </TitleProvider>
      </SidebarProvider>
    </UserProvider>
  );
};

export default App;
