import React, { useContext, useEffect } from "react";
import { TfiMenuAlt } from "react-icons/tfi";
import { FaRegBell } from "react-icons/fa6";
import SurvTask from "../components/SurvTask";
import { FaChevronRight } from "react-icons/fa6";
import { TitleContext } from "../context/TitleContext";
import { SidebarContext } from "../context/SidebarContext";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const { setTitle } = useContext(TitleContext);
  const { setSidebar } = useContext(SidebarContext);
  const navigate = useNavigate();

  useEffect(() => {
    setTitle("Home Page Title");
  }, [setTitle]);

  useEffect(() => {
    navigate("/onboard");
  }, [navigate]);

  useEffect(() => {
    setSidebar("open");
  }, [setSidebar]);

  const surv = {
    name: "survey",
    date: "2024/02/12",
    questionCount: 7,
    completedCount: 1,
  };

  return (
    <div className="mt-11 w-10/12 m-auto">
      <div className="mt-6 w-full flex items-start justify-between gap-10">
        <div className="w-[50%]">
          <p className="text-[#293951] font-semibold mb-10 flex items-center gap-2">
            <TfiMenuAlt />
            Гүйцэтгэх шаардлагатай зүйлс
          </p>
          <div className="mb-6 shadow-xl bg-white px-4 py-2 flex items-center gap-4 rounded-xl">
            <div className="w-[40%]">
              <div className="flex items-start gap-2 ">
                <p className="text-[#4F78A8] text-[20px] font-semibold">
                  50<span className="text-[16px]">pts</span>
                </p>
                <div>
                  <p className="font-semibold text-sm text-[#222]">Task name</p>
                  <p className="text-xs text[#222] opacity-80 mb-8">2 tasks</p>
                  <div className="ms-5 relative flex items-center justify-center bg-[#EEEFF1] border-[#324D72] border rounded-xl w-[25px] h-[15px]">
                    <img
                      className="absolute bottom-2"
                      src="./img/location.svg"
                      alt="location"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[70%]">
              <div className="flex justify-end">
                <p className="text-[#FF9D72] bg-[#FFF3ED] w-auto font-semibold mb-6 text-xs px-1.5 py-0.5 me-3 rounded-md text-end">
                  4 өдөр үлдсэн
                </p>
              </div>
              <p className="text-[#636466] text-xs">Эцсийн хугацаа:</p>
              <p className="text-[#222] text-xs font-semibold mb-3">
                2024/11/02
              </p>
              <button className="text-xs text-[#636466] flex items-center gap-2">
                Үргэлжлүүлэх <FaChevronRight />
              </button>
            </div>
          </div>
          <div className="flex items-center gap-4 py-2 px-5 bg-white rounded-xl shadow-xl mb-6">
            <div className="bg-[#E2B93B] bg-opacity-20 border border-[#E2B93B] text-[#E2B93B] font-black rounded-full px-3 py-0.5">
              !
            </div>
            <div>
              <p className="text-[#636466] font-semibold text-sm mb-3">
                Task name- 2 даалгавар дутуу
              </p>
              <div className="flex items-center gap-3 mb-1">
                <input
                  className="rounded-full bg-[#EBEBEB] border-[#BCBCBC] w-[12px] h-[12px]"
                  type="checkbox"
                />
                <p className="text-xs text-[#575763]">
                  Ажлын хувцас хүлээн авах
                </p>
              </div>
              <div className="flex items-center gap-3 mb-1">
                <input
                  className="rounded-full bg-[#EBEBEB] border-[#BCBCBC] w-[12px] h-[12px]"
                  type="checkbox"
                />
                <p className="text-xs text-[#575763]">
                  Хөдөлмөрийн гэрээтэй танилцах
                </p>
              </div>
            </div>
          </div>
          <div>
            <SurvTask data={surv} />
          </div>
        </div>
        <div className="w-[50%]">
          <p className="text-[#293951] font-semibold mb-10 flex items-center gap-2">
            <FaRegBell />
            Сүүлийн үеийн шинэчлэлүүд
          </p>
          <SurvTask data={surv} />
        </div>
      </div>
    </div>
  );
};

export default Home;
