import React, { useContext, useEffect, useState } from "react";
import { MdArrowBackIos, MdOutlineMenu, MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import menu from "../json/menu.json";
import Cookies from "js-cookie";
import { NightModeContext } from "../context/NightModeContext";
import { TitleContext } from "../context/TitleContext";
import { getUserData } from "../service/user.service";
import { UserContext } from "../context/userContext";
import { IoIosArrowDown } from "react-icons/io";
import { FaUser } from "react-icons/fa6";
import { CiSettings } from "react-icons/ci";

const Header = () => {
  const [popUp, setPopUp] = useState(false);
  const [menus, setMenus] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false); // State to control dropdown visibility
  const { user, setUser } = useContext(UserContext);
  const { title } = useContext(TitleContext);
  const [userData, setUserData] = useState(null);
  const { nightMode } = useContext(NightModeContext);
  const navigate = useNavigate();

  useEffect(() => {
    getUserData()
      .then((data) => {
        setUser(data.data);
        setUserData(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setMenus(menu[0].menus);
  }, []);

  return (
    <>
      <header
        className={`sticky top-0 border-b z-30 ${
          nightMode ? "bg-[#372763] border-none" : "bg-white  border-slate-200"
        }`}
      >
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between md:h-16 sm:h-12 h-12 -mb-px">
            <div className="flex items-center gap-6">
              {title === "Миний Profile settings" ? (
                <button
                  onClick={() => {
                    navigate("/");
                  }}
                  className={`flex me-10 items-center gap-1 text-sm ${
                    nightMode ? "text-white" : "text-[#1E293B]"
                  }`}
                >
                  <MdArrowBackIos />
                  Буцах
                </button>
              ) : (
                ""
              )}
              <p
                className={` ${
                  nightMode ? "text-white" : "text-[#1E293B]"
                } font-semibold md:text-[24px] md:hidden sm:block block sm:text-base text-base`}
              >
                {title}
              </p>
            </div>
            <div>
              <div className="items-center gap-4 md:flex sm:hidden hidden">
                <select
                  className={`px-2 py-2 border-none rounded-[10px] ${
                    nightMode
                      ? "text-white bg-[#372763]"
                      : "text-[#202224] bg-gray-100"
                  }`}
                >
                  <option value="en">Eng (US)</option>
                  <option value="mn">MGL</option>
                </select>

                <div
                  onClick={() => setShowDropdown(!showDropdown)} // Toggle dropdown
                  className="flex items-center gap-2 cursor-pointer"
                >
                  <div className="p-2 rounded-full   bg-[#ccc] border ">
                    <FaUser className=" text-[#fff]" />
                  </div>
                  <div>
                    <p
                      className={` ${
                        nightMode ? "text-white" : "text-[#081021]"
                      }`}
                    >
                      {userData?.firstName} {userData?.lastName}
                    </p>
                    <p
                      className={`text-sm text-center ${
                        nightMode ? "text-[#fff]" : "text-[#6c6c72]"
                      }`}
                    >
                      {userData?.departmentName}
                    </p>
                  </div>
                  <IoIosArrowDown
                    className={`transform transition-transform duration-400 ${
                      showDropdown ? "rotate-180" : ""
                    }`}
                  />
                </div>
                {showDropdown && (
                  <div
                    className={`absolute right-4 top-12 mt-2 w-48 bg-white border border-gray-200 rounded-md z-30 shadow-lg ${
                      nightMode ? "bg-[#372763]" : "bg-white"
                    }`}
                  >
                    <ul className="py-1">
                      <li
                        onClick={() => {
                          setShowDropdown(!showDropdown);
                          Cookies.remove("metacog_token");
                          window.location.reload();
                        }}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        Log Out
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <button
                onClick={() => {
                  setPopUp(true);
                }}
                className="md:hidden sm:block block"
              >
                <MdOutlineMenu
                  className={`font-bold text-2xl ${
                    nightMode ? "text-white" : "text-[#2C4360]"
                  }`}
                />
              </button>
            </div>
          </div>
        </div>
      </header>

      {popUp && (
        <div
          onClick={() => {
            setPopUp(false);
          }}
          className={`fixed top-0 left-0 w-full h-screen bg-[#000] bg-opacity-30 z-40 transform transition-all duration-300 ease-in-out ${
            popUp ? "opacity-100" : "opacity-0"
          }`}
          style={{ transitionProperty: "opacity, transform" }}
        >
          <div className="p-4 h-auto bg-white shadow-lg ">
            <div className="flex items-center justify-between">
              <p className="text-[#1E293B] font-semibold text-[24px]">
                Onboarding
              </p>
              <button onClick={() => setPopUp(false)}>
                <MdClose className="text-[#2C4360] text-2xl" />
              </button>
            </div>

            <div className="flex justify-between items-center gap-2 mt-4">
              <div className="flex items-center gap-2">
                <img
                  src="https://via.placeholder.com/40"
                  alt="profile"
                  className="rounded-full"
                />
                <div>
                  <p className="font-semibold text-[#081021]">
                    {userData.firstName}, {userData.lastName}
                  </p>
                  <p className="text-[#6B7280] text-sm">
                    {userData.departmentName}, {userData.position}
                  </p>
                </div>
              </div>
              {/* <CiSettings className="text-[#2C4360] text-xl" /> */}
            </div>

            <div className="mt-2">
              {menus?.map((items, l) => {
                if (items.id === 1 || items.id === 2) {
                  return (
                    <button
                      key={l}
                      className={`w-full text-start mb-2 px-2 py-2 rounded-lg hover:bg-[#f6f6f6] ${
                        selectedMenu === l
                          ? "bg-[#F4F6FB] text-[#324D72]"
                          : "bg-none text-[#666874]"
                      }`}
                      onClick={() => {
                        setSelectedMenu(l);
                        navigate(`${items.link}`);
                      }}
                    >
                      {items.name}
                    </button>
                  );
                } else {
                  return <></>;
                }
              })}
              <button
                onClick={() => {
                  setShowDropdown(!showDropdown);
                  Cookies.remove("metacog_token");
                  window.location.reload();
                }}
                className="ps-2 py-2 w-full rounded-lg text-start hover:bg-gray-100 cursor-pointer text-[#666874]"
              >
                Log Out
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
