import React, { useContext, useEffect, useState } from "react";
import { MdOutlineCake } from "react-icons/md";
import { MdOutlineLocalPhone } from "react-icons/md";
import { TitleContext } from "../context/TitleContext";
import { SidebarContext } from "../context/SidebarContext";
import { UserContext } from "../context/userContext";
import { FaPlus } from "react-icons/fa6";
import { IoIosClose } from "react-icons/io";
import { MdOutlineInsertLink } from "react-icons/md";
import { toast } from "react-toastify";
import { VscEye, VscEyeClosed } from "react-icons/vsc";

// const AddingDropdown = () => {
//   const [addedData, setAddedData] = useState([]);

//   return <div className="w-full border rounded-xl"></div>;
// };

const MyProfile = () => {
  const { setTitle } = useContext(TitleContext);
  const { setSidebar } = useContext(SidebarContext);
  const { user } = useContext(UserContext);

  const [hobby, setHobby] = useState([]);
  const [newHobby, setNewHobby] = useState("");
  const [hobbyShow, setHobbyShow] = useState(true);
  const [editingIndex, setEditingIndex] = useState(null);

  const [socialShow, setSocialShow] = useState(true);
  const [socialName, setSocialName] = useState("");
  const [socialLink, setSocialLink] = useState("");
  const [social, setSocial] = useState({
    name: "",
    link: "",
  });

  const [newPassword, setNewPassword] = useState("");
  const [showNew, setShowNew] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [showOld, setShowOld] = useState(false);

  const [repeatPassword, setRepeatPassword] = useState("");
  const [showRepeat, setShowRepeat] = useState(false);
  useEffect(() => {
    setTitle("Миний Profile settings");
  }, [setTitle]);

  useEffect(() => {
    setSidebar("closed");
  }, [setSidebar]);

  if (user === null) {
    return (
      <div className="flex items-center justify-center h-screen w-full">
        Loading...
      </div>
    );
  }

  const handleAddHobby = () => {
    if (newHobby.trim() === "") return;

    if (editingIndex !== null) {
      const updatedHobbies = [...hobby];
      updatedHobbies[editingIndex] = newHobby;
      setHobby(updatedHobbies);
      setEditingIndex(null);
    } else {
      setHobby([...hobby, newHobby]);
    }

    setNewHobby("");
  };

  const handleEditHobby = (index) => {
    setNewHobby(hobby[index]);
    setEditingIndex(index);
    const updatedHobbies = hobby.filter((_, i) => i !== index);
    setHobby(updatedHobbies);
  };

  const handleDeleteHobby = (index) => {
    const updatedHobbies = hobby.filter((_, i) => i !== index);
    setHobby(updatedHobbies);
  };

  const handleKeyDownHobby = (e) => {
    if (e.key === "Enter") {
      handleAddHobby();
    }
  };

  const handleKeyDownSocial = (e) => {
    if (e.key === "Enter") {
      handleSaveSocial();
    }
  };

  const handleSaveSocial = () => {
    const isValidUrl = (string) => {
      try {
        new URL(string);
        return true;
      } catch (err) {
        return false;
      }
    };

    if (socialName === "") {
      toast.error("Social name cannot be empty! Please enter a name.");
    }

    if (!isValidUrl(socialLink)) {
      toast.error("Invalid URL! Please enter a valid link.");
      return;
    }

    const social = {
      name: socialName,
      link: socialLink,
    };

    setSocialName("");
    setSocialLink("");
    setSocial(social);
    setSocialShow(false);
  };

  return (
    <div className="w-10/12 m-auto pt-11">
      {console.log(user)}
      <div className="flex items-start gap-10 w-full mb-20">
        <div className="w-[55%] px-6 py-3 bg-white shadow-xl rounded-xl">
          <p className="text-[#293951] font-semibold mb-6">Нэмэлт мэдээлэл</p>
          <div>
            <div className="flex items-center gap-6 mb-4">
              <div>
                <label className="block text-sm text-[#293951] font-semibold mb-1">
                  Төрсөн өдөр
                </label>
                <div className="relative">
                  <MdOutlineCake className="text-[#293951] text-xl absolute top-1.5 left-1" />

                  <input
                    className="rounded-md text-[#293951] text-sm border border-[#CECFD3] ps-6 pe-2 py-1 w-[132px]"
                    type="date"
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm text-[#293951] font-semibold mb-1">
                  Утасны дугаар
                </label>
                <div className="relative">
                  <MdOutlineLocalPhone className="text-[#293951] text-xl absolute top-1.5 left-1" />

                  <input
                    className="rounded-md text-[#293951] text-sm border border-[#CECFD3] ps-6 pe-2 py-1 w-[128px]"
                    type="number"
                  />
                </div>
              </div>
            </div>
            <div className="w-full border rounded-xl px-4 py-2 mb-4">
              <div className="flex items-center justify-between">
                <p className="text-[#293951]">Сонирхол/Хобби</p>
                <button
                  onClick={() => {
                    setHobbyShow(!hobbyShow);
                  }}
                  className=" text-[#293951]"
                >
                  {hobbyShow ? (
                    <IoIosClose className="text-3xl" />
                  ) : (
                    <FaPlus className="text-xl" />
                  )}
                </button>
              </div>
              {hobbyShow && (
                <div>
                  <hr className="bg-[#F3F4F6] mb-2 mt-2" />
                  <div className="flex items-center justify-between gap-2">
                    <input
                      className="w-full py-2 px-4 border rounded-md text-xs"
                      type="text"
                      value={newHobby}
                      onChange={(e) => setNewHobby(e.target.value)}
                      onKeyDown={handleKeyDownHobby}
                    />
                    <button
                      className="text-2xl text-[#293951]"
                      onClick={handleAddHobby}
                    >
                      <FaPlus />
                    </button>
                  </div>
                </div>
              )}
              {!hobbyShow && <hr className="bg-[#F3F4F6] mb-2 mt-2" />}
              {hobby.length !== 0 && (
                <div className="flex items-center gap-2 mt-1 flex-wrap">
                  {hobby.map((items, index) => (
                    <div
                      key={index}
                      className="border border-[#324D72] px-1 py-0.5 flex items-center rounded-lg"
                    >
                      <p
                        className="text-[#324d72] text-xs cursor-pointer"
                        onClick={() => handleEditHobby(index)}
                      >
                        {items}
                      </p>
                      <button
                        className="text-base text-[#324D72]"
                        onClick={() => handleDeleteHobby(index)}
                      >
                        <IoIosClose />
                      </button>
                    </div>
                  ))}
                </div>
              )}
              {hobby.length !== 0 && hobbyShow && (
                <div className="flex justify-end mt-2">
                  <button
                    onClick={() => {
                      setHobbyShow(false);
                    }}
                    className="px-5 py-2 text-[#EDEDED] bg-[#324d72] rounded-lg text-sm"
                  >
                    Хадгалах
                  </button>
                </div>
              )}
            </div>
            <div className="w-full border rounded-xl px-4 py-2">
              <div className="flex items-center justify-between">
                <p className="text-[#293951]">Social хаяг</p>
                <button
                  onClick={() => {
                    setSocialShow(!socialShow);
                  }}
                  className=" text-[#293951]"
                >
                  {socialShow ? (
                    <IoIosClose className="text-3xl" />
                  ) : (
                    <FaPlus className="text-xl" />
                  )}
                </button>
              </div>
              {!socialShow && (
                <div>
                  <hr className="bg-[#F3F4F6] mb-2 mt-2" />
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <MdOutlineInsertLink className="text-[#293951] text-2xl" />
                      <p className="text-[#000] text-sm ">{social.name}</p>
                      {social.link && (
                        <a
                          rel="noreferrer"
                          className="underline text-sm text-[#518CFF]"
                          target="_blank"
                          href={social.link}
                        >
                          {social.link}
                        </a>
                      )}
                    </div>
                    <button
                      onClick={() => {
                        setSocial({
                          name: "",
                          link: "",
                        });
                        setSocialShow(true);
                      }}
                    >
                      <img src="/img/trash.svg" alt="trash" />
                    </button>
                  </div>
                </div>
              )}
              {socialShow && (
                <div>
                  <hr className="bg-[#F3F4F6] mb-2 mt-2" />
                  <div className="flex items-center justify-between gap-2">
                    <div className="w-[50%]">
                      <label className="text-sm text-[#293951]">
                        Social линкийн нэр
                      </label>
                      <input
                        className="w-full py-2 px-4 border rounded-md text-xs"
                        type="text"
                        value={socialName}
                        onChange={(e) => setSocialName(e.target.value)}
                        onKeyDown={handleKeyDownSocial}
                      />
                    </div>
                    <div className="w-[50%]">
                      <label className="text-sm text-[#293951]">
                        Линк оруулна уу.
                      </label>
                      <div className="relative">
                        <MdOutlineInsertLink className="absolute left-2 top-[5px] text-[#293951] text-2xl" />
                        <input
                          className="w-full py-2 pe-4 ps-8 border rounded-md text-xs"
                          type="text"
                          value={socialLink}
                          onChange={(e) => setSocialLink(e.target.value)}
                          onKeyDown={handleKeyDownSocial}
                        />
                      </div>
                    </div>
                  </div>
                  {socialLink !== "" && socialName !== "" && socialShow && (
                    <div className="flex justify-end mt-2">
                      <button
                        onClick={handleSaveSocial}
                        className="px-5 py-2 text-[#EDEDED] bg-[#324d72] rounded-lg text-sm"
                      >
                        Хадгалах
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-[45%] px-6 py-3 bg-white shadow-xl rounded-xl">
          <p className="text-[#293951] font-semibold mb-6">Ерөнхий мэдээлэл</p>
          <div className="mt-4 flex items-center gap-4">
            <div className="w-14 h-14 rounded-full bg-[#f3f4f8]"></div>
            <div>
              <p className="text-[#1E293B] text-sm">
                {user.lastName} {user.firstName}
              </p>
              <p className="text-[#666874] text-sm">
                {user.departmentName}, {user.position}
              </p>
            </div>
          </div>

          <div className="mb-4 mt-5 flex flex-col gap-3">
            <div className="flex items-center justify-between">
              <p className="text-[#1E293B] text-xs">Мейл хаяг</p>
              <p className="text-[#666874] text-xs">Мейл хаяг</p>
            </div>
            <hr className="bg-[#F3F4F6]" />
            <div className="flex items-center justify-between">
              <p className="text-[#1E293B] text-xs">Ажиллаж эхэлсэн огноо</p>
              <p className="text-[#666874] text-xs">2024/04/01</p>
            </div>
            <hr className="bg-[#F3F4F6]" />
            <div className="flex items-center justify-between">
              <p className="text-[#1E293B] text-xs">Хэлтэс</p>
              <p className="text-[#666874] text-xs">{user.departmentName}</p>
            </div>
            <hr className="bg-[#F3F4F6]" />
            <div className="flex items-center justify-between">
              <p className="text-[#1E293B] text-xs">Баг</p>
              <p className="text-[#666874] text-xs">{user.teamName}</p>
            </div>
            <hr className="bg-[#F3F4F6]" />
            <div className="flex items-center justify-between">
              <p className="text-[#1E293B] text-xs">Дээд албан тушаалтан</p>
              <p className="text-[#666874] text-xs">Employee name</p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-6 py-3 bg-white shadow-xl rounded-xl w-full">
        <p className="text-[#293951] ">Нууц үг солих</p>
        <div className="flex flex-col items-start mt-4 px-20 gap-2 w-full">
          <div className="flex items-center justify-center gap-10 w-full">
            <div className="w-[45%]">
              <label className="text-[#293951] text-sm">
                Одоогийн нууц үг оруулна уу.
              </label>
              <div className="relative">
                <img
                  className="absolute top-1 left-2"
                  src="/img/key.svg"
                  alt="key"
                />
                <input
                  value={oldPassword}
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                  }}
                  className="w-full py-2 ps-10 pe-4 border rounded-md"
                  type={!showOld ? "password" : "text"}
                />
                <button
                  onClick={() => {
                    setShowOld(!showOld);
                  }}
                  className="absolute top-2.5 right-2 "
                >
                  {showOld ? (
                    <VscEyeClosed className="text-[#ABB1BB] text-xl" />
                  ) : (
                    <VscEye className="text-[#ABB1BB] text-xl" />
                  )}
                </button>
              </div>
            </div>
            <div className="w-[45%]">
              <label className="text-[#293951] text-sm">
                Шинэ нууц үг оруулна уу.
              </label>
              <div className="relative">
                <img
                  className="absolute top-1 left-2"
                  src="/img/key.svg"
                  alt="key"
                />
                <input
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                  className="w-full py-2 ps-10 pe-4 border rounded-md"
                  type={!showNew ? "password" : "text"}
                />
                <button
                  onClick={() => {
                    setShowNew(!showNew);
                  }}
                  className="absolute top-2.5 right-2 "
                >
                  {showNew ? (
                    <VscEyeClosed className="text-[#ABB1BB] text-xl" />
                  ) : (
                    <VscEye className="text-[#ABB1BB] text-xl" />
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center gap-10 w-full">
            <div className="w-[45%]">
              <label className="text-[#293951] text-sm">
                Шинэ нууц үг оруулна уу.
              </label>
              <div className="relative">
                <img
                  className="absolute top-1 left-2"
                  src="/img/key.svg"
                  alt="key"
                />
                <input
                  className="w-full py-2 ps-10 pe-4 border rounded-md"
                  value={repeatPassword}
                  onChange={(e) => {
                    setRepeatPassword(e.target.value);
                  }}
                  type={!showRepeat ? "password" : "text"}
                />
                <button
                  onClick={() => {
                    setShowRepeat(!showRepeat);
                  }}
                  className="absolute top-2.5 right-2 "
                >
                  {showRepeat ? (
                    <VscEyeClosed className="text-[#ABB1BB] text-xl" />
                  ) : (
                    <VscEye className="text-[#ABB1BB] text-xl" />
                  )}
                </button>
              </div>
            </div>
            <div className="w-[45%]" />
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-6 mt-4 mb-10">
        <button className="text-[#1E293B] border border-[#EDEDED] bg-[#fff] rounded-lg px-6 py-2">
          Буцах
        </button>
        <button className="text-[#EDEDED] bg-[#324D72] rounded-lg px-6 py-2">
          Хадгалах
        </button>
      </div>
    </div>
  );
};

export default MyProfile;
