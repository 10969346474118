import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../service/user.service";
import { toast, ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    setLoading(true);

    e.preventDefault();
    login({
      username: username,
      password: password,
    })
      .then((res) => {
        if (res.data.accessToken) {
          const token = res.data.accessToken;
          Cookies.set("metacog_token", token, { expires: 7 });

          navigate({ pathname: "/" });
          setLoading(false);
        } else {
          toast.error("Invalid username or passwordsss");
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Invalid username or password");
        setLoading(false);
      });
  };

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="border rounded-xl p-6 ">
        <p className="text-xl mb-4 font-semibold">Login</p>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col mb-2">
            <label>Username: </label>
            <input
              className="border border-xl rounded-lg px-4 py-2"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="flex flex-col mb-2">
            <label>Password: </label>
            <div className="relative">
              <input
                className="border border-xl rounded-lg  py-2 ps-4 pe-7"
                type={show ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div
                onClick={() => {
                  setShow(!show);
                }}
                className="absolute right-2 top-3 cursor-pointer"
              >
                {show ? (
                  <IoEyeOutline className="text-xl" />
                ) : (
                  <IoEyeOffOutline className="text-xl" />
                )}
              </div>
            </div>
          </div>
          <button
            className="border rounded-xl px-4 py-2 bg-black text-white text-sm mt-2 flex items-center justify-center"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <svg
                className="animate-spin h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8h8a8 8 0 11-16 0z"
                ></path>
              </svg>
            ) : (
              "Login"
            )}
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
