import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { FaChevronRight } from "react-icons/fa6";

ChartJS.register(ArcElement, Tooltip);

const SurvTask = ({ data }) => {
  const [percentage, setPercentage] = useState(0);
  const [gradient, setGradient] = useState(0);
  useEffect(() => {
    const pecentages = Math.round(
      (data.completedCount / data.questionCount) * 100
    );
    const grad = (pecentages % 10) / 10;
    setPercentage(pecentages);
    setGradient(grad);
  }, [data]);

  const [hover, setHover] = useState(false);
  const totalSlices = 10;
  const filledSlices = Math.floor((percentage / 100) * totalSlices);

  const chartData = Array(totalSlices).fill(1);

  const getGradient = (ctx, chartArea) => {
    if (!chartArea) return "#E9EDF5";

    const { left, right } = chartArea;
    const gradientSegment = ctx.createLinearGradient(left, 0, right, 0);

    gradientSegment.addColorStop(0, "#324D72");

    gradientSegment.addColorStop(gradient, "#324D72");

    gradientSegment.addColorStop(1, "#E9EDF5");

    return gradientSegment;
  };

  const DoghnutData = {
    datasets: [
      {
        data: chartData,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          return chartData.map((_, index) => {
            if (index < filledSlices) return "#324D72";
            else if (index === filledSlices) return getGradient(ctx, chartArea);
            return "#E9EDF5";
          });
        },
        borderWidth: 2,
        borderColor: "#FFFFFF",
        cutout: "70%",
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: { enabled: false },
    },
  };

  return (
    <div
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      className="bg-[#FFFFFF] rounded-xl shadow-xl flex items-center gap-4 px-4 py-2"
    >
      {console.log(data)}
      <div className="relative flex items-center justify-center w-28 h-28">
        <Doughnut data={DoghnutData} options={options} />
        <div className="absolute text-xl font-semibold text-gray-900 mt-1">
          {percentage}%
        </div>
      </div>
      <div className="w-full">
        <div className="flex items-center justify-between">
          <p className="text-[#636466] font-semibold mb-3">{data.name}</p>
          <p className="text-[#FF9D72] bg-[#FFF3ED] font-semibold mb-3 text-xs px-1.5 py-0.5 me-3 rounded-md">
            4 өдөр үлдсэн
          </p>
        </div>
        <div className="flex items-center gap-2 justify-between">
          <div>
            <p className="text-[#636466] text-xs">Асуулт:</p>
            <p className="text-[#222222] text-xs font-semibold">
              {data.questionCount}
            </p>
          </div>
          <div>
            <p className="text-[#636466] text-xs">Илгээсэн огноо:</p>
            <p className="text-[#222222] text-xs font-semibold">{data.date}</p>
          </div>
          <div>
            <p className="text-[#636466] text-xs">Эцсийн хугацаа::</p>
            <p className="text-[#222222] text-xs font-semibold">2023/12/21</p>
          </div>
        </div>
        <button
          className={`text-[#636466] text-xs mt-3 flex items-center transition-opacity duration-300 ease-in-out ${
            hover ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
        >
          Үргэлжлүүлэх <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default SurvTask;
