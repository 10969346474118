import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const MobileFeedback = ({ inboxData, sendData }) => {
  const height = window.innerHeight - 240;
  const [table, setTable] = useState("ALL");
  const [selected, setSelected] = useState("inbox");
  const navigate = useNavigate();

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `/${year}/${month}/${day}`;
  }

  const isLatest = (dateString) => {
    const createdDate = new Date(dateString);
    const currentDate = new Date();

    const timeDifference = currentDate - createdDate;
    const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;

    return timeDifference <= threeDaysInMs;
  };

  const getItems = () => {
    if (selected === "inbox") {
      if (table === "ALL") return inboxData.all || [];
      if (table === "START") return inboxData.start || [];
      if (table === "STOP") return inboxData.stop || [];
      if (table === "CONTINUE") return inboxData.con || [];
    } else if (selected === "send") {
      if (table === "ALL") return sendData.all || [];
      if (table === "START") return sendData.start || [];
      if (table === "STOP") return sendData.stop || [];
      if (table === "CONTINUE") return sendData.con || [];
    }
    return [];
  };

  return (
    <div className="px-[20px] pt-2">
      <div className="flex justify-end">
        <button
          onClick={() => {
            navigate("search");
          }}
          className="text-[#fff] text-sm flex items-center gap-1 bg-[#324d72] rounded-md px-3 py-2"
        >
          <FaPlus />
          Feed-Forward өгөх
        </button>
      </div>
      <div className="mb-4">
        <select
          onChange={(e) => {
            setSelected(e.target.value);
          }}
          className="text-[#324D72] text-xs px-2 py-1 border border-[#CEDAE9] rounded-md bg-[#F4F6FB]"
        >
          <option value="inbox">Хүлээн авсан</option>
          {/* <option value="send">Илгээсэн</option> */}
        </select>
      </div>
      <div className="flex items-center justify-between gap-2">
        <button
          onClick={() => {
            setTable("ALL");
          }}
          className={` pb-1 ${
            table === "ALL"
              ? "text-[#324d72] border-b-2 border-[#324d72]"
              : "text-[#666874 border-none "
          }`}
        >
          Бүгд [
          {selected === "inbox" ? inboxData.all.length : sendData.all.length}]
        </button>

        <button
          onClick={() => {
            setTable("START");
          }}
          className={` pb-1 ${
            table === "START"
              ? "text-[#324d72] border-b-2 border-[#324d72]"
              : "text-[#666874 border-none "
          }`}
        >
          start [
          {selected === "inbox"
            ? inboxData.start.length
            : sendData.start.length}
          ]
        </button>

        <button
          onClick={() => {
            setTable("STOP");
          }}
          className={` pb-1 ${
            table === "STOP"
              ? "text-[#324d72] border-b-2 border-[#324d72]"
              : "text-[#666874 border-none "
          }`}
        >
          stop [
          {selected === "inbox" ? inboxData.stop.length : sendData.stop.length}]
        </button>

        <button
          onClick={() => {
            setTable("CONTINUE");
          }}
          className={` pb-1 ${
            table === "CONTINUE"
              ? "text-[#324d72] border-b-2 border-[#324d72]"
              : "text-[#666874 border-none "
          }`}
        >
          Continue [
          {selected === "inbox" ? inboxData.con.length : sendData.con.length}]
        </button>
      </div>

      <div style={{ height: `${height}px` }} className="mt-4 overflow-y-scroll">
        {getItems().length === 0 ? (
          <div className="flex flex-col items-center justify-center ">
            <img src="/img/feedback/feedback.svg" alt="nothing" />
            <p className="text-[#1E293B] text-sm">
              Илгээсэн feed-forward байхгүй
            </p>
          </div>
        ) : (
          getItems().map((items, index) => (
            <div
              key={index}
              className="w-full p-4 bg-white rounded-xl mb-4 shadow-custom"
            >
              <div className="flex items-center justify-between">
                <p className="text-[#64748B] text-xs py-0.5">
                  {formatDate(items.createdDate)}
                </p>
                {isLatest(items.createdDate) && (
                  <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED]">
                    <p className="text-[#FF9D72] font-semibold text-sm">new</p>
                  </div>
                )}
              </div>
              <div>
                <p className="text-xs text-[#222] py-2">{items.text}</p>
              </div>
              <div>
                <p className="text-[11px] text-end text-[#222] font-semibold">
                  Илгээсэн:{" "}
                  {items.hide
                    ? "Anonymous"
                    : `${items.lastName || ""} ${items.firstName || ""}`}
                </p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default MobileFeedback;
